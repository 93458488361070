@import './fonts';
@import './variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-position: inside;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  -webkit-overflow-scrolling: touch;
  font-family: $main-font;
  height: 100%;
  position: relative;
}

body {
  line-height: 1.5;
  &::-webkit-scrollbar {
    width: 0;
  }
}

a {
  text-decoration: none;
  color: #255599;

  &:visited,
  &:active {
    color: #255599;
  }
}
