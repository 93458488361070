$desktop: 990px;

$main-font: LatoRegular;
$control_height: 35px;

/* Global Colors */

$global-color-green: #9ecc4e;
$global-color-red: #e44100;

/* Top Navigation Bar */

$nav-background-color: $global-color-green;

/* Header Bar */

$header-bar-background-color: #eee;
$header-bar-border-color: #aaa;
$header-bar-font-family: LatoRegular;
$header-bar-font-size: 24px;
$header-bar-default-font-size: 16px;
$header-bar-color: #333;

/* Controls */

$control-label-color: #666;
$control-label-font-family: LatoRegular;
$control-label-font-size: 13px;
$control-label-text-transform: uppercase;

$control-label-value-font-family: LatoRegular;
$control-label-value-font-size: 15px;
$control-label-value-color: #000;

$control-border-color: #ccc;

$control-font-family: LatoRegular;
$control-font-size: 15px;
$control-color: #000;
$control-placeholder-color: #999;

/* Save/Cancel Buttons */

$button-save-color: #fff;
$button-save-background-color: $global-color-green;
$button-save-hover-background-color: #8bb546;

$button-cancel-color: #255599;
$button-cancel-hover-text-decoration: underline;

/* Section */

$section-header-title-font-family: LatoRegular;
$section-header-title-font-size: 15px;
$section-header-title-color: $global-color-green;
$section-header-text-transform: uppercase;

$section-header-label-font-family: LatoRegular;
$section-header-label-font-size: 13px;
$section-header-label-color: #666;
$section-header-label-text-transform: uppercase;

$section-header-label-value-font-family: LatoRegular;
$section-header-label-value-font-size: 15px;
$section-header-label-value-color: #000;
