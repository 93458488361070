.tabs {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 40px;
  padding: 0;
  background: #fff;

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 40px;
    font-family: LatoRegular;
    font-size: 14px;
    color: #000;
    border-top: 3px solid transparent;
    border-left: 1px solid #e1dede;
    border-bottom: 1px solid #e1dede;
    background-color: #fff;
    text-transform: uppercase;
    padding: 0 10px;
    position: relative;
    cursor: pointer;

    &:last-child {
      border-right: 1px solid #e1dede;
    }

    &:hover {
      background-color: #e6e6e6;
    }

    &.active {
      border-top: 3px solid #9ecc4e;
      border-bottom: 1px solid transparent;
    }

    &:hover {
      background-color: #e6e6e6;
    }
  }
}
