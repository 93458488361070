@font-face {
  font-family: 'LatoLight';
  src: url('../static/fonts/Lato-Light.woff') format('woff'),
  url('../static/fonts/Lato-Light.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LatoRegular';
  src: url('../static/fonts/Lato-Regular.woff') format('woff'),
  url('../static/fonts/Lato-Regular.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LatoMedium';
  src: url('../static/fonts/Lato-Medium.woff') format('woff'),
  url('../static/fonts/Lato-Medium.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LatoSemiBold';
  src: url('../static/fonts/Lato-Semibold.woff') format('woff'),
  url('../static/fonts/Lato-Semibold.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LatoBold';
  src: url('../static/fonts/Lato-Bold.woff') format('woff'),
  url('../static/fonts/Lato-Bold.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}